const initialState = {
    siteList: [],
    listSites: [],
    isWorkorderLoaded: false,
    isListLoaded:false,
    isProposalLoaded: false,
    loadingExportCSV:false,
}

const siteReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_LIST':
            return {...state, siteList: action.payload}
        case 'LIST_SITES':
                return {...state, listSites: action.payload}
        case 'LOADING_LIST':
                return {...state, isListLoaded: action.payload}
        case 'LOADING_EXPORT_CSV':
            return {...state, loadingExportCSV: action.payload}

        case 'RELOAD_WORKORDER':
            return {...state, isWorkorderLoaded: action.payload}
        case 'RELOAD_PROPOSAL':
            return {...state, isProposalLoaded: action.payload}
        case 'SET_SITE_DETAILS':
            return {...state, siteDetail: action.payload}
        case 'CLEAR_SITE_LIST':
            return {...state, 
                siteList: []
            }
        default:
            return state
    }
}

export default siteReducer
